#faq {
    background: #F8F9F9;
    width: 100% !important;
    max-width: 1400px !important;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-bottom: 60px !important;
    padding-top: 20px !important;
    margin-top: 85px;
}

#faq #search {
    width: 100%;
    margin: 4rem 0;
}


#faq #search .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 77px;
    color: #1B1232;
}

#faq #search .subTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #707685;
}

#faq #search #searchInput {
    width: 60%;
}

#faq #cards {
    padding-bottom: 4rem !important;
    padding-top: 4rem !important;
    background-color: #FFFFFF;
}

#faq #cards:hover {
    cursor: pointer;
}

#faq #cards #faqItem {
    background: #F8F9F9;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.02);
    border-radius: 6px;
}

#faq #cards #faqItem .faqName {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #1B1232;
    margin-left: 3rem;
}

#faq #cards #faqItem .icon {
    width: 6rem;
    height: 6rem;
    background: linear-gradient(90deg, #CA40F0 0%, #3E80DC 100%);
    border-radius: 6px 0px 0px 6px;
}

#faq #cards .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 58px;
    color: #1B1232;
}

#faq #cards .subTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #707685;
    max-width: 30%;
}

#faq #submitRequest .grid .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 58px;
    text-align: center;
    color: #1B1232;
    /* flex: none;
    order: 0;
    flex-grow: 0; */
}

#faq #submitRequest .grid .subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #707685;
}

#faq #submitRequest .grid .button {
    background: linear-gradient(90deg, #CA40F0 0%, #3E80DC 100%);
    border-radius: 8px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

#faq #submitRequest .grid .button span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    color: #F8F9F9;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0.5rem 3rem;
}

#faq .grid {
    padding-left: 1rem !important;
}

@media only screen and (max-width: 600px) {
    #faq #search .grid {
        padding: 2rem 0.5rem !important;
    }

    #faq .main {
        padding: 0px 1rem !important;
    }

    #faq #cards .card.MuiGrid-item {
        padding-left: 0px !important;
    }

    #faq #cards {
        padding-bottom: 2rem !important;
        padding-top: 2rem !important;
    }

    #faq #cards .title {
        text-align: center;
    }

    #faq #cards .subTitle {
        max-width: 100%;
        text-align: center;
    }

    #faq #search .title {
        text-align: center;
    }

    #faq #search .subTitle {
        text-align: center;
    }

    #faq #search #searchInput {
        width: 100%;
    }

    #faq #cards #faqItem .faqName {
        margin-left: 1.5rem;
    }
    
}

@media only screen and (min-width: 601px)  and (max-width: 900px) {
    /* #faq .main {
        padding: 0px 1rem !important;
    } */

    #faq #cards {
        padding-bottom: 2rem !important;
        padding-top: 2rem !important;
    }

    #faq #cards .card.MuiGrid-item:nth-child(even) {
        padding-left: 0px !important;
    }
    
}