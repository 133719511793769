#phone {
    min-height: 70vh;
    max-width: 1400px;
    padding: 120px 60px !important;
}

#phone .carousel .navButton:hover {
    cursor: pointer;
}

#phone .carousel {
    outline: none !important;
}

#phone .carousel div {
    outline: none !important;
}

#phone .itemContent  {
    overflow: inherit;
    margin: 0px;
    -webkit-filter: blur(1px);    
    filter: blur(1px) grayscale(80%);
    padding-top: 50px;
}

#phone .itemContent.selected {
    opacity: 1;
    filter: none;
    text-align: center;
    padding-top: 0px;
}

#phone .itemImage {
    max-width: 100%;
}

#phone .itemImage.selected {
    max-width: 100%;
}

#phone .name {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    padding-top: 15px;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: #000000;
}

#phone .empty{
    min-height: 40px;
}

@media only screen and (min-width: 301px)  and (max-width: 900px) {
    #phone {
        min-height: 20vh;
        max-width: 95%;
        padding: 0px !important;
    }
}