#coming {
    background: linear-gradient(134.07deg, #FFE4F0 12.77%, #D6D6FF 130.76%);
    min-width: 100%;
    min-height: 100vh;
}

#coming .top {
    text-align: left;
    padding: 5vh;
}

#coming .title {
    text-align: center;
    padding-top: 20vh;
}

#coming .textGrid {
    text-align: left;
}

#coming .buttonGrid {
    text-align: right;
    margin: 1rem;
}

#coming .button {
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 1rem;
    min-width: 4    0%;
}

#coming .button .text {
    padding-left: 3rem;
    padding-right: 3rem;
}

#coming .joinWhitelist {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    #coming .title {
        text-align: center;
        padding-top: 5vh;
    }
    
    #coming .textGrid {
        text-align: center;
    }
    
    #coming .buttonGrid {
        text-align: center;
    }

    #coming .button {
        margin-top: 0rem;
        margin-bottom: 0rem;
        margin-left: 0rem;
    }
    
    #coming .button .text {
        padding-left: 0rem;
        padding-right: 0rem;
    }

    #coming .joinWhitelist {
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (min-width: 601px)  and (max-width: 900px) {
    #coming .title {
        text-align: center;
        padding-top: 5vh;
    }

    #coming .textGrid {
        text-align: center;
    }
    
    #coming .buttonGrid {
        text-align: center;
    }

    #coming .button {
        margin-top: 0rem;
        margin-bottom: 0rem;
        margin-left: 1rem;
    }
    
    #coming .button .text {
        padding-left: 0rem;
        padding-right: 0rem;
    }
}