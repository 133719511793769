#About {
    position: relative !important;
    width: 100% !important;
    max-width: 1400px !important;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-bottom: 60px !important;
    padding-top: 20px !important;
    margin-top: 85px;
}

#About .header {
    padding-bottom: 4rem;
}

#About .future {
    padding-bottom: 4rem;
}

#About .aboutCards {
    margin-bottom: 4rem;
}

#About .who {
    padding-bottom: 4rem;
}

#About .future .upSection{
    margin-bottom: auto;
    width: 70%;
}

#About .future .downSection{
    margin-top: auto;
    width: 70%;
}

#About .future .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 58px;
    color: #1B1232;
    padding-bottom: 1rem;
}

#About .future .text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #707685;
}

#About .headerText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 77px;
    text-align: center;
    color: #1B1232;
}

#About .headerSubText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #707685;
}

#About .aboutCards {
    background: #1B1232;
}

#About .textTitleLight {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 58px;
    color: #FFFFFF;
    padding: 0 6rem;
}

#About .textLight {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #F8F9F9;
    padding: 0 6rem;
}

#About .padding {
    padding: 6rem;
}

#About .paddingCards {
    padding-top: 10rem;
    padding-left: 6rem;
}

#About .cardsImage {
    max-width: 100%;
}

#About #whoAre {
    background: url('../../assets/images/about_network.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    background-position-y: 10vh;
}

#About #whoAre .bottom {
    padding: 1rem 0;
}

#About #whoAre .section {
    background: #F8F9F9;
    padding: 10rem 15rem;
}

#About #whoAre .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 58px;
    text-align: center;
    color: #1B1232;
    padding-bottom: 1rem;
}

#About #whoAre .text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #707685;
    padding-bottom: 1rem;
}

@media only screen and (max-width: 600px) { 
    #About {
        padding: 0px 0px !important;
    }

    #About .padding {
        padding: 0.2rem;
    }

    #About .headerText {
        font-size: 32px;
        padding: 1rem;
        padding-top: 2rem;
    }
    
    #About .textTitle {
        font-size: 24px;
        padding-top: 1rem;
    }
    
    #About .text {
        font-size: 16px;
        padding-top: 1rem;
    }

    #About .paddingCards {
        padding-top: 1rem;
        padding-left: 1rem;
    }

    #About .textLight {
        padding: 0 2rem;
    }

    #About .textTitleLight {
        padding: 0 2rem;
    }

    #About #futureMobile {
        background: url('../../assets/images/future_mobile.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position-x: center;
    }
    
    #About #futureMobile .text {
        background: #F8F9F9;
        padding: 2rem 0;
    }

    #About .future .upSection{
        width: 100%;
        padding-bottom: 2rem;
    }
    
    #About .future .downSection{
        width: 100%;
    }

    #About #futureMobile .top {
        padding: 1rem 0;
    }

    #About #whoAre .section {
        padding: 2rem 1rem;
    }
    
}

@media only screen and (min-width: 601px)  and (max-width: 900px) {
    #About .padding {
        padding: 2rem;
        margin-left: auto;
        margin-right: auto;
    }

    #About .paddingCards {
        padding-top: 2rem;
        padding-left: 0;
    }

    #About #futureMobile {
        background: url('../../assets/images/future_mobile.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position-x: center;
    }
    
    #About #futureMobile .text {
        background: #F8F9F9;
        padding: 2rem 1rem;
    }

    #About .future .upSection{
        width: 100%;
        padding-bottom: 2rem;
    }
    
    #About .future .downSection{
        width: 100%;
    }

    #About #futureMobile .top {
        padding: 1rem 0;
    }
    
    #About #whoAre .section {
        padding: 2rem 2rem;
    }
}