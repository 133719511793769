#primaryBtn {
    background: linear-gradient(90deg, #CA40F0 0%, #3E80DC 100%);
    border-radius: 8px;
    height: 56px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    color: #F8F9F9;
    text-transform: none;
    padding-left: 1rem;
    padding-right: 1rem;
}

#secondaryBtn {
    border: 1px solid #1B1232;
    box-sizing: border-box;
    border-radius: 8px;
    height: 56px;
    background: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 16px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    color: #1B1232;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
    text-transform: none;
}