#Plans {
    position: relative !important;
    width: 100% !important;
    max-width: 1400px !important;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-bottom: 60px !important;
    padding-top: 20px !important;
    margin-top: 85px;
}

#Plans .title {
    font-size: 64px;
    line-height: 120%;
    color: #1B1232;
    text-align: center;
}

#Plans .mobile {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    padding-top: 3vh;
    color: #1B1232;
    text-align: center;
}

#Plans .smallTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 130%;
    text-align: center;
    color: #1B1232;
    padding-top: 3vh;
}

#Plans .price {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 140%;
    text-align: center;
    color: #1B1232;
    padding-top: 2vh;
    padding-bottom: 3vh;
}

#Plans .rowTitle {
    font-style: normal;
    font-size: 18px;
    line-height: 140%;
}

#Plans .rowText {
    font-style: normal;
    font-size: 14px;
    color: #B5B9C4;
    line-height: 150%;
}

#Plans .rowDataText {
    font-style: normal;
    font-size: 14px;
    line-height: 150%;
    padding-top: 1.3vh;
}

#Plans .rowDataImage {
    padding-top: 1vh;
}

#Plans .headerRowTop {
    min-height: 30vh;
}

#Plans .textRowTop {
    border-top: 2px solid #ECDAF8;
    margin-top: 2vh;
}

#Plans .headerRowBottom {
    min-height: 20vh;
}

#Plans .dataRow {
    min-height: 10vh;
    text-align: center;
    vertical-align: middle;
    display: "flex";
    justify-content: center;
    align-content: center;
}

#Plans .dataRowStart {
    min-height: 10vh;
    text-align: left;
}

#Plans .dataRowSelected {
    background-color: #F7F3F7;
    border-left: 2px solid #ECDAF8;
    border-right: 2px solid #ECDAF8;
}

#Plans .dataItem {
    min-height: 8vh;
}

#Plans .button {
    min-width: 60%;
}

#Plans .gridTitle{
    font-size: 18px;
    line-height: 30px;
    color: #1B1232;
}

#Plans .description{
    font-size: 14px;
    line-height: 50px;
    color: #707685;
    
}

#Plans .price{
    font-size: 36px;
    line-height: 50px;
    font-weight: 700;
    color: #000000;
}

#Plans .compare{
    font-size: 18px;
    line-height: 30px;
    text-align: right;
    color: #3E80DC;
    text-decoration: none;
}

#Plans .month{
    font-size: 18px;
    line-height: 50px;
    vertical-align: baseline;
    color: #707685;
}

#Plans .planCard{
    background: #F8F9F9;
    border-radius: 10px;
    padding: 2rem;
}

#Plans .button {
    width: 80%;
}

#Plans .buttonGrid {
    padding: 2rem 0 2rem 0;
    text-align: center;
}

#Plans .headerNeeds {
    background: #F8F9F9;
    border-radius: 10px 10px 0 0;
    margin-top: 1rem;
    min-height: 6vh;
}

#Plans .headerNeedsTitle {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #1B1232;
}

#Plans .listLeft {
    border-left: 1px solid #F8F9F9;
    border-bottom: 1px solid #F8F9F9;
}

#Plans .listCenter {
    border-bottom: 1px solid #F8F9F9;
}

#Plans .listRight {
    border-right: 1px solid #F8F9F9;
    border-bottom: 1px solid #F8F9F9;
}

#Plans .spacer{
    background: #ffffff;
    min-height: 3px;
}

@media only screen and (max-width: 600px) { 

    #Plans {
        padding: 1rem;
    }

    #Plans .price {
        font-size: 24px;
        padding-bottom: 1vh;
    }

    #Plans .title {
        padding-bottom: 1vh;
    }

    #Plans .smallTitle {
        font-size: 24px;
    }

    #Plans .headerRowTop {
        border-bottom: 0px;
        min-height: 25vh;
        position: sticky;
    }

    #Plans .headerRowSelectedTop {
        background-color: #F7F3F7;
        border: 2px solid #ECDAF8;
        border-radius: 10px;
        min-height: 25vh;
        position: sticky;
    }
    
    #Plans .dataRowSelected {
        background-color: #F7F3F7;
        border: 2px solid #ECDAF8;
        border-radius: 10px;
    }
        
    #Plans .dataRowStart {
        min-height: 6vh;
        text-align: center;
        padding-top: 1vh;
    }

    #Plans .dataRow {
        min-height: 6vh;
    }

    #Plans .button {
        max-width: 85%;
    }

    #Plans .listGrid {
        padding: 1rem 0 1rem 0;
    }

    #Plans .planCard{
        padding: 1rem;
    }
}