#footer {
    background: #1B1232;
    width: 100%;
}

#footer .footerContent {
    position: relative !important;
    width: 100% !important;
    max-width: 1400px !important;
    margin-right: auto !important;
    margin-left: auto !important;
    padding: 1rem 60px !important;
}

#footer .links {
    display: flex;
    flex-direction: column;
}

#footer .links a {
    font-size: 16px;
    line-height: 100%;
    color: #ffffff;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0.4rem 0px;
    text-decoration: none;
}

#footer .links a:hover {
    cursor: pointer;
}

#footer .apps .icons {
    display: flex;
    flex-direction: column;
}

#footer .apps a:hover {
    cursor: pointer;
}

#footer .header {
    font-weight: bold;
    font-size: 18px;
    line-height: 110%;
    color: #FFFFFF;
    text-transform: uppercase; 
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 1em;
    margin-top: 0;
}

#footer .copyright {
    font-size: 14px;
    line-height: 150%;
    color: #ffffff;
}

#footer .links.mobile {
    display: none;
}

#footer .image {
    max-width: 95%;
}

@media only screen and (max-width: 600px) {
    #footer .footerContent {
        padding: 2rem !important;
    }

    #footer .apps {
        display: flex;
        flex-direction: column;
    }

    #footer .links.desktop.tablet {
        display: none;
    }

    #footer .links.mobile {
        display: unset;
    }
    
    #footer .links.mobile .collapsedLinks a {
        display: flex;
        text-align: center;
        padding-right: 25px;
        flex-direction: column;
        margin: 0.5em 0.5em;
    }
    
    #footer .links.mobile .button {
        text-align: center;
        padding-left: 0; 
        padding-right: 0;
        color: #ffffff;
    }
    
    #footer .links.mobile .text span {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 150%;
        color: #ffffff;
    }
    
    #footer .apps .header {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 150%;
        color: #ffffff;
    }

    #footer .apps .icons {
        display: unset;
        flex-direction: unset;
    }

    #footer .apps .icons img {
        width: 95%;
        max-height: 60px;
    }
    
  }
  
  @media only screen and (min-width: 601px)  and (max-width: 900px) {
    #footer .footerContent {
        padding: 0 1rem !important;
    }

    #footer .header {
        font-weight: bold;
        font-size: 14px;
    }

    #footer .links a {
        font-size: 12px;
    }

    #footer .copyright {
        font-size: 10px;
    }

    #footer .links a {
        margin: 0.5em 0px;
    }
  }