.LandingTop {
    position: relative !important;
    width: 100% !important;
    max-width: 1400px !important;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-bottom: 60px !important;
    padding-top: 20px !important;
    margin-top: 85px;
}

.LandingTop .alignChildren {
    text-align: -webkit-center;
}