
#navbar .main {
    max-width: 1400px;
    box-shadow: none;
}

#navbar .button {
    width: 150px;
}

#navbar .logo {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: unset !important;
}

#navbar .MuiGrid-item {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

#navbar .menu {
    display: flex;
    justify-content: center;
    align-items: center;
}

#navbar .menu div[data-nav] {
    display: flex;
    
}

#navbar .social {
    display: flex;
    justify-content: end;
    align-items: center;
}

#navbar .social>a {
    line-height: 0;
}


#navbar .social .social-logo {
    margin: 0px 0.25rem;
}

#navbar .navbarItem {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.04em;
    color: #413E57;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 1rem;
    width: 90px;
    display: flex;
    justify-content: center;
    text-decoration: unset;
}

#navbar .navbarItem:hover {
    cursor: pointer;
}

#navbar .navbarItem.active {
    text-decoration-line: underline;
    text-underline-offset: 10px;
}

#navbar .mobile {
    display: none;
}

@media only screen and (max-width: 1399px) { 

    #navbar .mobile {
        display: flex;
    }

    #navbar .desktop {
        display: none;
    }

    #navbar .mobile .drawer {
        margin-left: auto;
    }

    #navbar img {
        display: flex;
        justify-content: center;
    }

    #mobileDrawer .socials.links.drawer {
        display: flex;
        justify-content: space-evenly;
    }

    #mobileDrawer .MuiDrawer-paper {
        background: linear-gradient(90deg, #CA40F0 0%, #3E80DC 100%);
        color: #FFFFFF;
        width: 100%;
    }

    #mobileDrawer .list {
        margin-left: auto;
        margin-right: auto;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 48px;
        line-height: 58px;
        text-align: center;
    }
    
}