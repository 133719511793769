#privacy {
    width: 100%;
    text-align: center;
    margin: 1rem;
    margin-top: 3rem;
}

#privacy .privacyBox {
    max-width: 1400px;
    margin-top: 3rem;
    margin-bottom: 3rem;
    display: inline-block;
    text-align: left;
}

#privacy .title {
    font-size: 35px;
    text-align: left;
}

#privacy .subtitle {
    font-size: 25px;
    text-align: left;
}
