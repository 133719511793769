#ourPlans {
    padding: 0px 60px !important;
    text-align: left;
}

#ourPlans .plans{
    padding: 6rem 0 6rem 0;
}

#ourPlans .planCard{
    background: #F8F9F9;
    border-radius: 10px;
    padding: 2rem;
    min-height: 35rem;
}

#ourPlans .spacer{
    background: #ffffff;
    max-height: 3px;
}

#ourPlans .title{
    font-size: 18px;
    line-height: 30px;
    color: #1B1232;
}

#ourPlans .description{
    font-size: 14px;
    line-height: 50px;
    color: #707685;
    
}

#ourPlans .price{
    font-size: 36px;
    line-height: 50px;
    font-weight: 700;
    color: #000000;
}

#ourPlans .compare{
    font-size: 18px;
    line-height: 30px;
    text-align: right;
    color: #3E80DC;
    text-decoration: none;
}

#ourPlans .month{
    font-size: 18px;
    line-height: 50px;
    vertical-align: baseline;
    color: #707685;
}

#ourPlans .buttonGrid {
    padding: 2rem 0 2rem 0;
    text-align: center;
}

#ourPlans .listGrid {
    padding: 2rem 0 2rem 0;
}

#ourPlans .button {
    width: 80%;
}

#ourPlans .button .text{
    padding-left: 0.1rem;
    padding-right: 0.1rem;
}

#ourPlans .planListIcon {
    min-width: 30px;
}

#ourPlans .planList {
    padding: 0px;
}

@media only screen and (max-width: 600px) {
    #ourPlans {
        padding: 0px 0.5rem !important;
    }

    #ourPlans .plans{
        padding: 0.5rem;
    }

    #ourPlans .button .text{
        padding-left: 0.1rem;
        padding-right: 0.1rem;
    }

    #ourPlans .planCard .content{
        margin: 0px 5px;
    }
}

@media only screen and (min-width: 601px) and (max-width: 900px) {
    #ourPlans {
        padding: 0px 0.5rem !important;
    }

    #ourPlans .planCard {
        min-height: 35rem;
    }

    #ourPlans .plans{
        padding: 2rem 0 0 0;
    }
    
    #ourPlans .planCard .header span.MuiCardHeader-title{
        font-size: 28px;
    }

    #ourPlans .button .text{
        padding-left: 0.1rem;
        padding-right: 0.1rem;
    }

    #ourPlans .title{
        font-size: 18px;
        line-height: 22px;
        color: #1B1232;
    }
    
    #ourPlans .description{
        font-size: 14px;
        line-height: 50px;
        color: #707685;
        
    }
    
    #ourPlans .price{
        font-size: 36px;
        line-height: 40px;
        font-weight: 700;
        color: #000000;
    }
    
    #ourPlans .compare{
        font-size: 18px;
        line-height: 22px;
        text-align: right;
        color: #3E80DC;
        text-decoration: none;
    }
    
    #ourPlans .month{
        font-size: 18px;
        line-height: 50px;
        vertical-align: baseline;
        color: #707685;
    }
    
}