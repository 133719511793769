.main {
  position: relative !important;
  width: 100% !important;
  margin-right: auto !important;
  margin-left: auto !important;
  padding: 0px !important;
  margin-top: 80px;
  align-items: center;
  justify-content: center;
}

a:hover {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .main {
      padding: 0px !important;
  }

  section {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@media only screen and (min-width: 601px)  and (max-width: 900px) {
  .main {
      padding: 0px !important;
  }

  section {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}