#download {
    background: #F8F9F9;
    border-radius: 8px;
}

#download .downloadGrid {
    position: relative !important;
    width: 100% !important;
    margin-right: auto !important;
    margin-left: auto !important;
    padding: 0px 60px !important;
}

#download .hand {
    max-width: 80%;
}

#download .downloadSpacer {
    padding-top: 2vh;
}

@media only screen and (max-width: 600px) {
    #download {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    #download .downloadGrid {
        padding: 0px !important;
        text-align: center;
    }
    
    #download .downloadSpacer {
        padding-top: 1vh;
    }
}
  
@media only screen and (min-width: 601px)  and (max-width: 900px) {
    #download {
        padding-left: 0 1rem !important;
        padding-right: 0 1rem !important;
        text-align: center;
    }

    #download .downloadGrid {
        padding: 0px !important;
    }

    #download .downloadSpacer {
        padding-top: 0vh;
        padding-left: 5rem;
        padding-right: 5rem;
    }
}