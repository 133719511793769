#terms {
    width: 100%;
    text-align: center;
    margin: 1rem;
    margin-top: 3rem;
}

#terms .termsBox {
    max-width: 1400px;
    margin-top: 3rem;
    margin-bottom: 3rem;
    display: inline-block;
    text-align: left;
    width: inherit;
    min-height: 65vh;
}

#terms .title {
    font-size: 35px;
    text-align: left;
}

#terms .subtitle {
    font-size: 25px;
    text-align: left;
}

#terms .subtitle2 {
    font-size: 20px;
    text-align: left;
}