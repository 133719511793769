.navigation .menuTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #707685;
}

.navigation nav {
    padding-top: 0 !important;
}

.navigation .menuTitle.selected {
    font-style: normal;
    font-weight: 600;
    color: #1B1232;
}

.navigation .menuSubTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #707685;
}

.navigation .menuSubTitle.selected {
    font-style: normal;
    font-weight: 600;
    color: #1B1232;
}

.navigation .collapse {
    border-left: 4px solid #105099;
}

.navigation .button {
    padding-left: 0px;
    padding-top: 0px;
}


.content .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 58px;
    color: #1B1232;
}

.content .subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #1B1232;
    padding-bottom: 2rem;
}