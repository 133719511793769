#IBAN {
    position: relative !important;
    width: 100% !important;
    max-width: 1400px !important;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-bottom: 60px !important;
    padding-top: 20px !important;
}

#IBAN .title {
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 120%;
    color: #1B1232;
    text-align: left;
}

#IBAN .text {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    color: #413E57;
}

#IBAN .phone {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    color: #413E57;
    padding-top: 3vh;
}

#IBAN .button {
    min-width: 100px;
}

#IBAN .image {
    max-width: 100%;
}

@media only screen and (max-width: 600px) { 
    #IBAN {
        padding: 20px 0.5rem !important;
    }

    #IBAN .title {
        padding-top: 3vh;
    }

    #IBAN .button {
        min-width: 100%;
    }
}